import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import "./styles.sass";
import { postSlug } from "templates/Blog/utils/slugs";
import Heading from "components/common/utils/Heading";

export const SmallCard = ({ post }) => {
  const { title, featuredImage, headingAs = "h2" } = post;
  return (
    <Link to={postSlug(post)} className="small-card">
      <div className="small-card-image-wrapper">
        <GatsbyImage
          image={featuredImage.node.localFile.childImageSharp.gatsbyImageData}
          className="img-fluid small-card-image"
          alt={post.title}
        />
      </div>
      <Heading as={headingAs} className="h4 small-card-title">{title}</Heading>
    </Link>
  );
};
