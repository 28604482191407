import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React, { useState } from "react";
import BlogLayout from "components/common/blog/BlogLayout";
import CharlesMeta from "components/common/meta/CharlesMeta";
import { graphql, Link } from "gatsby";
import Segment from "utils/segment";
import Breadcrumb from "templates/Blog/common/Breadcrumb";
import { tagBreadcrumbs } from "templates/Blog/common/Breadcrumb";
import PostCard from "templates/Blog/common/PostList/common/PostCard";
import cx from "classnames";
import './styles.sass'
import { SmallCard } from "templates/Blog/common/Card/SmallCard";
import { chunk, first } from 'utils/lodashExtracts';
import { tagSlug } from "templates/Blog/utils/slugs";

const IndexPage = (props) => {
  const { page, recentPosts, allTags } = props.data;
  const displayTags = allTags.edges;
  const articles = recentPosts.nodes;
  const displayPostsLimit = 6;
  let morePosts = [];
  let chunkedPosts = [];
  if (articles.length > displayPostsLimit) {
    morePosts = articles.slice(displayPostsLimit);
    chunkedPosts = chunk(morePosts, morePosts.length / 2);
  }
  const [shouldShowPosts, setShouldShowPosts] = useState(false);
  const displayedTitle = first(page.seo.title.split("|"))
  return (
    <BlogLayout>
      <CharlesMeta
        title={page.seo.title}
        description={page.seo.metaDesc}
        segmentCategory={Segment.CATEGORIES.BLOG}
      />
      <Container className="">
        <Breadcrumb links={tagBreadcrumbs(page)} />
        <h1>{displayedTitle}</h1>
      </Container>
      {page.description && (
        <div className="tag-description-wrapper">
          <Container>
            <Row>
              <Col>
                <div className="tag-description" dangerouslySetInnerHTML={{ __html: page.description }} />
              </Col>
            </Row>
          </Container>
        </div>
      )}
      <Container className="blog-tag-container">
        <Row className="blog-tag-card-row">
          {recentPosts.nodes.map((post, i) => (
            // set display none if more than 6 posts
            <Col className={cx('blog-tag-card-col', {'d-none': i >= displayPostsLimit})} xs={12} sm={6} md={6} lg={4}>
              <PostCard post={post} headingAs="h2"/>
            </Col>
          ))}
        </Row>
        {shouldShowPosts && (
          <Row>
            {chunkedPosts.map((chunk, i) => (
              <Col className="chunked-posts-columns" lg={6}>
                {chunk.map((post, i) => (
                  <SmallCard key={i} post={post} />
                ))}
              </Col>
            ))}
          </Row>
        )}
        <div className={cx("show-more-article-button-wrapper", {'d-none': !(recentPosts.nodes.length >= displayPostsLimit && !shouldShowPosts)})}>
          <button className={cx('btn show-more-article-button')} onClick={() => setShouldShowPosts(true)}>+ Voir tous les articles {page.name}</button>
        </div>
        <div className="separator-tag" />
        <Row className="tag-list-row">
          <Col className="tag-list-col">
            <div className="tag-list-wrapper">
              {displayTags.map((tag) => (
                <Link className="tag" to={tagSlug(tag.node)} key={tag.node.id}>
                  {tag.node.name}
                </Link>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </BlogLayout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query BlogTagById($id: String!) {
    page: wpTag(id: { eq: $id }) {
      id
      name
      slug
      description
      seo {
        metaDesc
        title
      }
      posts {
        nodes {
          id
          title
        }
      }
    }
    recentPosts: allWpPost(
      filter: { tags: { nodes: { elemMatch: { id: { eq: $id } } } } }
      sort: { fields: date, order: DESC }
    ) {
      nodes {
        id
        title
        excerpt
        link
        uri
        featuredImage {
          node {
            ...BlogImage
          }
        }
        categories {
          nodes {
            id
            slug
            name
          }
        }
      }
    }
    allTags: allWpTag (
      filter:{
        slug: { regex: "/^(?!no-page-).*$/" }
        id: {ne: $id}
      }
    ) {
      edges {
        node {
          id
          name
          slug
          link
        }
      }
    }
  }
`;
